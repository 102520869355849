import _ from "lodash";
import { createSelector } from "reselect";
import { TUser } from "types";
import { DefaultDisplayPicture } from "utils/config";
import { RootState } from "store";

export const isLoggedIn = createSelector(
  [(state: RootState) => state.user.data],
  user => user.uid !== ""
);

export const isLoggingIn = createSelector(
  [(state: RootState) => state.user.loading],
  loading => loading.loggingIn === true
);

export const getUserData = (state: RootState) => state.user.data;

/**
 * Used to force updates based on user/data even if
 * values don't change.
 */
export const getUserDataUpdateTime = (state: RootState) => state.user.data.ts;

export const getUserId = createSelector([getUserData], user => user.uid);

export const getUsername = createSelector([getUserData], user => user.user);

export const getDisplayPicture = createSelector(
  [getUserData],
  user => user.displayPicture || DefaultDisplayPicture
);

export const getNickname = createSelector(
  [getUserData],
  user => user.displayName
);

export const getDisplayName = createSelector(
  [
    (state: RootState) => getUsername(state),
    (state: RootState) => getNickname(state),
  ],
  (username, nickname) => nickname || username
);

export const getUserHeaderInfo = createSelector(
  [
    (state: RootState) => getUserId(state),
    (state: RootState) => getDisplayName(state),
    (state: RootState) => getDisplayPicture(state),
    (state: RootState) => getVanityUrl(state),
  ],
  (uid, displayName, displayPicture, slug) => ({
    uid,
    displayName,
    displayPicture,
    slug,
  })
);

export const isSubscribed = (type: SubscriptionTypes) =>
  createSelector(
    [(state: RootState) => state.user],
    user =>
      user.data.subscriptions.items.find(sub => sub.type === type) !== undefined
  );

export const getVanityUrl = createSelector(
  [(state: RootState) => state.user],
  user => user.data.slug
);

export const isAllowed = (state: RootState) => state.user.data.active;

export const getUserDescription = (state: RootState) =>
  state.user.data.description;

export const isLongDescription = (state: RootState) =>
  getUserDescription(state)?.length > 140;

export const isSavingUserDescription = (state: RootState) =>
  state.user.loading.setUserDescription;

export const getIsPublic = (state: RootState) => state.user.data.isPublic;

export const getEmailConfirmed = (state: RootState) =>
  state.user.data.isEmailConfirmed;

export const getProfileCompletionStep = (
  state: RootState
): keyof TUser["profileCompletion"] => {
  const steps = _.pickBy(state.user.data.profileCompletion, v => v === false);
  return Object.keys(steps)[0] as keyof TUser["profileCompletion"] | undefined;
};
